@import '@/theme/vars';

//Header Button
.header {
  .space-3-btn-text {
    height: 36px;
    display: flex !important;
    align-items: center !important;
    .space-3-icon {
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}

.header-desktop {
  &--container {
    height: 65px !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw !important;
    background: #141417;
    z-index: 100 !important;
  }

  &--inner {
    height: 65px !important;
    padding: 0 35px !important;
  }
}

.header-mobile {
  width: 100% !important;
  height: 52px !important;
  background: #141417;
  position: fixed;
  z-index: 100;

  &--btn-switch {
    margin-left: 16px;
    color: $TEXT_COLOR_PRIMARY !important;

    &:hover {
      color: $PRIMARY !important;
    }
  }
}

// Header Menu Navigation
.header--menu-nav--desktop {
  width: 100%;
  justify-content: center !important;
  background: transparent !important;
  border-bottom: transparent !important;

  &:after,
  &:before {
    display: none !important;
  }

  .space-3-menu-item,
  .space-3-menu-submenu {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 8px !important;
    color: $TEXT_COLOR_PRIMARY !important;
    height: 65px !important;
    overflow: hidden !important;
    .space-3-icon {
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
    &:after {
      left: 10px !important;
      right: 10px !important;
    }
    &:not(:has(.a8-mainnet--btn-item)) {
      &:hover {
        color: $PRIMARY !important;
        &:after {
          border-bottom-color: $PRIMARY !important;
        }
      }
    }
    &:has(.a8-mainnet--btn-item, .disable-active) {
      &:hover {
        &:after {
          border-bottom-color: transparent !important;
        }
      }
    }

    .space-3-menu-title-content > a {
      color: inherit !important;
    }
  }

  .space-3-menu-item-selected,
  .space-3-submenu-item-selected {
    background: transparent !important;
    color: $PRIMARY !important;
    .space-3-icon {
      color: $PRIMARY !important;
      svg {
        color: $PRIMARY !important;
      }
    }
    .space-3-menu-title-content {
      color: $PRIMARY !important;
    }
    &:not(:has(.a8-mainnet--btn-item)) {
      &:after {
        border-bottom-color: $PRIMARY !important;
      }
    }
    &:has(.a8-mainnet--btn-item, .disable-active) {
      &:after {
        border-bottom-color: transparent !important;
      }
      .a8-mainnet--btn-item {
        background-color: #aee7601a !important;
      }
    }
    &:before {
      position: absolute !important;
      content: '';
      width: 60px;
      height: 20px;
      display: block !important;
      bottom: -14px;
      left: 50%;
      background: $PRIMARY;
      opacity: 0.3;
      filter: blur(11.5px);
      border-radius: 80px;
      transform: translateX(-50%);
    }
  }
}

.header--menu-nav--mobile {
  border-inline-end: none !important;
  background: transparent;

  .space-3-menu-item {
    padding: 0 12px 0 0;
    display: flex;
    align-items: center;
    border-radius: 0;
    width: fit-content;
    border-bottom: 1px solid transparent !important;
    height: 50px;

    &-icon svg {
      width: 24px !important;
      height: 24px !important;
    }

    &:hover,
    &-selected {
      background: transparent !important;
      border-bottom: 1px solid $PRIMARY !important;

      svg {
        color: $PRIMARY !important;
      }

      .space-3-menu-title-content {
        color: $PRIMARY !important;
      }
    }

    &:hover:has(.disable-active),
    &-selected:has(.disable-active) {
      border-bottom: transparent !important;
    }

    .space-3-menu-title-content a {
      color: $TEXT_COLOR_PRIMARY !important;
      padding-left: 16px !important;
      font-size: 16px;
    }
  }
}

.nav-desktop {
  ul {
    border-radius: 12px !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.75) !important;
    border: 0.5px solid var(--main-style-2, #29333daa) !important;

    li {
      padding: 12px !important;
      background-color: $BG_CONTENT;
      margin: 0 !important;
      width: 100% !important;
      display: flex !important;
      align-items: center;
      border-bottom: 0.5px solid var(--main-style-2, #29333daa);
      border-radius: 0 !important;

      &:has(div.active) {
        background-color: $BG_CONTENT_LIGHT;
      }

      :last-child {
        border-bottom-color: transparent !important;
      }
    }
  }

  &--menu {
    font-size: 14px;
  }
}

.leaderboard-nav {
  div {
    background-color: transparent !important;
  }
}
