.a8-mainnet {
  &--btn {
    color: #aee760 !important;
    font-weight: 300;
    line-height: initial !important;

    &:hover {
      background-color: #aee7601a !important;
    }
  }

  &--btn-box {
    background-color: #aee7601a !important;
  }
}
