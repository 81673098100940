.app-container {
  width: 100%;
  min-height: calc(100vh - 64px);

  //Due to removed sidebar, use max-width 1230px
  max-width: 1230px !important;
  margin: 0 auto !important;
  //NOTE: padding left and right 24px to prevent Row - gap scroll
  padding: 0 24px;

  @media screen and (max-width: 991px) {
    padding: 0;
    max-width: 100vw !important;
  }
}

// Reward Center
.reward-center-container {
  padding-top: 37px;

  @media screen and (max-width: 991px) {
    padding-top: 0;
  }

  .space-3-tabs-nav {
    margin: 0;
    .space-3-tabs-tab {
      margin: 0;
      padding: 16px 24px;
    }
  }
}
