@import '@/theme/vars';

.user-nav--btn-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-color: $PRIMARY;
  }
}

.user-nav--dropdown-content .space-3-dropdown-menu,
.a8-dropdown-menu {
  width: 250px;
  margin-top: 10px;
  padding: 16px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  .space-3-dropdown-menu-item {
    padding: 0;

    &:hover {
      background-color: transparent;
      span {
        color: $PRIMARY !important;
      }
    }
    &:last-child {
      &:hover {
        span {
          color: $WARNING !important;
        }
      }
    }
    &:nth-child(2) {
      &:hover {
        cursor: default !important;
      }
    }
  }

  .user-name {
    display: flex;
    justify-content: space-between;
    .space-3-space-item {
      button {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &:hover {
          background-color: transparent;
          .space-3-icon {
            &:hover {
              color: $PRIMARY !important;
            }
          }
        }
      }
    }

    .clipboard {
      .space-3-icon {
        color: #4d5a66 !important;
      }
    }
  }
}
