@import '@/theme/vars';

.daily-checkin-container {
  position: relative;
  padding: 0 !important;

  .overlay-container {
    position: relative;

    .overlay-section {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(11, 16, 18, 0.6);
      backdrop-filter: blur(6px);
      z-index: 2;
    }
  }
}
