@import '@/theme/vars';

@mixin sp3-modal-base {
  padding: 0;
  position: relative;
  border-radius: 16px;
}

@mixin sp3-modal-close-base {
  position: absolute;
  cursor: pointer;
  width: fit-content;
  height: auto;
  opacity: 0.65;

  &:hover {
    opacity: 1;
    background: none !important;
  }

  .space-3-modal-close-x {
    font-size: 32px;
    color: $PRIMARY;
  }
}

.sp3-modal {
  @include sp3-modal-base;

  &--default {
    @extend .sp3-modal;

    .space-3-modal-content {
      border-radius: 16px;
      border: 1px solid $PRIMARY;
      background: transparent;
    }
  }

  &--primary {
    @extend .sp3-modal;

    .space-3-modal-content {
      border-radius: 16px;
      border: none;
      background: transparent;
      padding: 12px !important;
    }
  }

  &--secondary {
    @extend .sp3-modal;
    background-color: #070d0e !important;

    .space-3-modal-content {
      border-radius: 16px;
      border: none;
      background: transparent;
      padding: 40px !important;
    }
  }

  /* Close styles */
  &--close-default > .space-3-modal-content > .space-3-modal-close {
    @include sp3-modal-close-base;
  }

  &--close-bottom-outer > .space-3-modal-content > .space-3-modal-close {
    @include sp3-modal-close-base;

    top: calc(100% + 10px);
    right: calc(50% - 20px);
    background: #1e1e1ecc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 4px;
  }

  &--close-top-right-outer > .space-3-modal-content > .space-3-modal-close {
    @include sp3-modal-close-base;

    top: -32px;
    right: -32px;
  }
}
