@import '@/theme/vars';

.sp3-modal {
  background: #111519 !important;
}

.daily-checkin-wrap-modal {
  .space-3-modal-content,
  .a8-modal-content {
    padding: 18px !important;
    border: 1px solid $PRIMARY;
    border-radius: 16px;

    .space-3-modal-close,
    .a8-modal-close {
      cursor: pointer;
      width: fit-content;
      height: auto;
      opacity: 0.65;

      &:hover {
        opacity: 1;
        background: none !important;
      }

      .space-3-modal-close-x,
      .a8-modal-close-x {
        font-size: 32px;
        color: $PRIMARY;
      }
    }
  }
}

.daily-checkin--main-btn {
  color: $WARNING !important;
  display: flex !important;
  align-items: center;
  &:hover {
    background: #ff73001a !important;
    box-shadow: 0 0 4px 0 $WARNING;
  }
}

.daily-checkin__banner-section {
  .sp3-swiper-pagination-outer {
    margin-bottom: 0 !important;
  }

  .swiper-slides-container {
    .swiper {
      padding: 0;
    }

    .swiper-pagination-bullets {
      text-align: center;

      .swiper-pagination-bullet {
        background-color: rgba(15, 219, 209, 1) !important;
        opacity: 0.3;
        width: 7.5px;
        height: 7.5px;
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }

  .daily-checkin__banner-item {
    height: 320px;

    .space-3-image,
    .a8-image {
      height: 100%;
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    .daily-checkin__banner-item {
      height: 135px;

      img {
        object-fit: contain;
      }
    }
  }
}

.daily-checkin__contents {
  &--btn {
    width: 116px;
    text-align: center;
    color: $WARNING !important;
    background: #ff73001a !important;
    opacity: 0.85;

    &:hover {
      opacity: 1;
      box-shadow: 0 0 4px 0 $WARNING;
    }
  }

  .daily-checkin__contents--daily-reward {
    width: 116px;
  }
}

.daily-checkin__contents-mobile {
  .sp3-swiper-pagination-outer {
    margin-bottom: 0;
  }

  .sp3-swiper-container {
    position: relative;
    display: block;
    bottom: 0 !important;
  }

  .daily-checkin__contents--daily-reward {
    width: unset !important;
  }

  .streak-reward-thumbs-sw-inner {
    padding: 0;
  }
  .streak-reward-thumbs-sw-swiper-prev-element,
  .streak-reward-thumbs-sw-swiper-next-element {
    z-index: 2;
  }

  .streak-reward-thumbs-sw-swiper-pagination {
    bottom: 8px !important;
  }

  .checkin-reward-card {
    .space-3-card-body,
    .a8-card-body {
      padding: 4px !important;
    }

    &__contents {
      &--title {
        font-size: 24px !important;
      }

      &--streak-title {
        font-size: 18px !important;
      }
    }
  }
}

.checkin-reward-card {
  display: flex;
  border-radius: 12px;
  background: #1f262f;
  justify-content: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 2px 0 $PRIMARY;
  }

  &.checkin-reward-card--eligible {
    box-shadow: 0 0 8px 0 $PRIMARY;
  }

  &.checkin-reward-card--active {
    box-shadow: 0 0 4px 0 $PRIMARY;

    &:before {
      position: absolute;
      top: -18px;
      content: '';
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $PRIMARY;
    }
  }

  &.checkin-reward-card--missed {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.checkin-reward-card--checked-in-without-reward {
    cursor: pointer;
  }

  &.checkin-reward-card--claimed,
  &.checkin-reward-card--checked-in {
    cursor: not-allowed;

    .checkin-reward-card__contents--thumb {
      z-index: -1;
    }
  }

  &.checkin-reward-card--upcoming {
    cursor: not-allowed;
  }

  .overlay-container {
    position: relative;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $PRIMARY;
      z-index: 2;

      svg {
        @media screen and (max-width: $MAX_WIDTH_MOBILE) {
          width: 24px;
          height: auto;
        }
      }
    }
  }

  .space-3-card-body,
  .a8-card-body {
    padding: 6px;
    width: 100%;

    .checkin-reward-card__contents {
      height: 100%;

      &--title,
      &--streak-title {
        font-weight: 400;
        font-family: DIN, sans-serif;
        user-select: none;
      }

      &--streak-title {
        font-style: italic;
      }

      &--title {
        font-size: 30px;
        font-style: italic;
        white-space: nowrap;
        line-height: 100%;
        margin: 6px 0 !important;
      }

      &--streak-title {
        font-size: 21px;
        text-align: center;
        line-height: 100%;
        color: $PRIMARY;
        word-break: break-word;
        white-space: pre-line;
      }

      &--thumb-container {
        width: 100%;
        aspect-ratio: 1/1 !important;
        object-fit: cover;

        .space-3-spin-nested-loading,
        .space-3-spin-container {
          width: 100%;
          height: 100%;
        }

        .space-3-image,
        .a8-image {
          width: 100%;
          height: 100%;
          text-align: center;
        }

        .checkin-reward-card__contents--thumb {
          user-select: none;
          border-radius: 8px;
          border: 0.5px solid $SECONDARY;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
    }
  }
}

.col-align-center,
.col-align-end {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.col-align-center {
  justify-content: center;
}

.col-align-end {
  justify-content: end;
}

.centered-elem {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (max-width: $MAX_WIDTH_MOBILE) {
  .daily-checkin-wrap-modal {
    .space-3-modal {
      width: 440px !important;
    }
  }
}
